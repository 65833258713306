@import "src/style.scss";
.content {
  overflow: hidden;
  width: 960px;
  display: grid;
  grid-template-areas:
    "slider products"
    "page  page";

  @media (max-width: 1000px) {
    width: 100vw;
    grid-template-areas:
      "slider"
      "products"
      "page";
  }
  &.no-slider {
    grid-template-areas: "page products";
    .page {
      width: 700px;
      @media (max-width: 1000px) {
        width: 100vw;
      }
    }
  }
  .page {
    width: 100%;
  }
}

.page {
  grid-area: page;
  overflow: hidden;
  width: 100%;
}

* {
  margin: 0px;
  padding: 0px;
}

.our-products {
  &-aside {
    position: relative;
    grid-area: products;
    flex-grow: 999;
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
    @media (max-width: 1000px) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    border-radius: 4px;
    background-color: $main-backgorund-default;
    &-header {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      height: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #004074;
    }
    &-list {
      list-style: none;
      > li {
        position: relative;
        width: 110%;
        left: -5%;
        margin-top: 5px;
        line-height: 20px;
        min-height: 20px;
        svg {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 20px;
        }
        > a {
          position: absolute;
          left: 15px;
          top: 0px;
          line-height: 20px;
          text-decoration: none;
          color: $sub-color-default;
        }
        > ul {
          transition: max-height 0.3s;
          max-height: 0px;
          overflow: hidden;
          list-style: none;
          > li {
            line-height: 20px;
            min-height: 20px;
            position: relative;
            border-bottom: 1px solid #212121;
            margin: 0px 10px;
            &:hover {
              > a {
                color: #ffda00;
              }
            }
            > a {
              color: black;
              position: absolute;
              top: 0px;
              line-height: 20px;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
