// TODO: remove old style
// @import "./old-styles.scss";

html,
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f6f6f6;
    @media (max-width: 1000px) {
        background-color: #fff;
    }
}

body {
    max-width: 960px;
    margin: auto;
    
}

.fs-14 {
    font-size: 14px;
}

.fs-16 {
    font-size: 16px;
}

.fs-20 {
    font-size: 16px;
}
.ta-j {
    text-align: justify;
}

$color-blue-default: #005689;
$color-yellow-default: #ffda00;
$main-backgorund-default: #fff;
$main-color-default: #212121;
$sub-color-default: #fff;