.slider-main {
    position: relative;
    overflow: hidden;
    grid-area: slider;
    width: 700px;
    border-radius: 4px;
    background-color: #fff;
    @media (max-width: 1000px) {
        width: calc(100vw - 32px);
        padding: 0px 16px;
        height: 300px;
        background-color: inherit;
    }
    &-btn {
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        svg {
            width: 34px;
            height: 34px;
            &:focus{
                outline:none;
            }
        }
        &.previous{
            left: 0px;
            transform: rotate(180deg) translateY(50%);
        }
        &.next{
            right: 0px;
        }
    }
    &-slide {
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}
