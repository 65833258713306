@import "src/style.scss";

.our-products {
    position: relative;
    width: 100%;
    height: 316px;
    display: flex;
    flex-direction: row;

    &-slider {
        width: 700px;
        border-radius: 4px;
        background-color: $main-backgorund-default;
    }

    &-aside {
        flex-grow: 999;
        width: 200px;
        margin-left: 20px;
        margin-right: 20px;
        @media (max-width: 1000px) {
            margin-left: unset;
            margin-right: unset;
            margin: auto;
        }
        border-radius: 4px;
        background-color: $main-backgorund-default;
        &-header {
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
            height: 25px;
            font-size: 18px;
            font-weight: bold;
            color: #004074;
        }
        &-list {
            list-style: none;
            li {
                position: relative;
                width: 110%;
                left: -5%;
                margin-top: 5px;
                line-height: 20px;
                svg {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    height: 100%;
                }
                a {
                    position: absolute;
                    width: calc(100% - 15px);
                    left: 15px;
                    top: 0px;
                    line-height: 20px;
                    text-decoration: none;
                    color: $sub-color-default;
                    &.active {
                        font-weight: 900;
                    }
                }
            }
        }
    }
}
