@import "../style.scss";

.footer {
    background-color: #005689;
    color: white;
    border-radius: 8px 8px 0px 0px;
    padding: 16px 8px;
    margin-top: 10px;
    margin-right: 20px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    @media (max-width: 1000px) {
        margin-right: 0px;
    }
    &-logo {
        width: 100px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &-contacts{
        div {
            margin: 4px 0px;
        }
        span {
            margin: 4px 0px;
        }
        a {
            color: #d3d3d3;
            text-decoration: none;
        }
    }
}