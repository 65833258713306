@import "../style.scss";

header {
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  .messangers {
    position: absolute;
    right: 80px;
    top: 15px;
    height: 20px;
    img {
      height: 100%;
    }
  }

  .localization {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 50px;
    ul {
      justify-content: space-around;
    }
  }

  nav {
    position: absolute;
    top: 40px;
    left: 300px;
    right: 20px;
    height: 32px;
    background-color: $color-blue-default;
    ul {
      justify-content: space-around;
      list-style: none;
      display: flex;
      flex-direction: row;
      a {
        font-weight: bold;
        font-size: 14px;
        color: white;
        text-decoration: none;
        line-height: 32px;
        &:hover {
          color: $color-yellow-default;
        }
        &:active {
        }
      }
    }
  }
}
