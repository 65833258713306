@import "../style.scss";

.menuButton {
  padding: 10px;
  margin: 0px;
  border: 0px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  visibility: hidden;
  background: none;
  @media (max-width: 1000px) {
    visibility: visible;
  }
}

.closeButton {
  padding: 10px;
  margin: 0px;
  border: 0px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: none;
}

.backgroundAside {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: none;
}

.backgroundAsideOpen {
  display: block;
}

.aside {
  position: absolute;
  top: 0px;
  width: 300px;
  background: white;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s;
  z-index: 10;
  padding: 8px;
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
  nav {
    height: 50%;
    display: block;
    position: relative;
  }
  ul {
    justify-content: space-around;
    height: 100%;
    display: flex;
    flex-direction: column;

    a {
      font-weight: bold;
      font-size: 20px;
      text-decoration: none;
      line-height: 32px;
      &:hover {
        color: $color-yellow-default;
      }
    }
  }
}

.asideOpen {
  transform: translateX(0px);
}

.asideClose {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.header {
  position: relative;
  width: calc(100% - 20px);
  height: 105px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px;
  @media (max-width: 1000px) {
    background-position: right;
  }
}

.headerNavigation {
  position: absolute;
  top: 40px;
  left: 300px;
  right: 20px;
  height: 32px;
  background-color: $color-blue-default;
  display: block;
  @media (max-width: 1000px) {
    display: none;
  }
  > ul {
    justify-content: space-around;
    > a {
      font-weight: bold;
      font-size: 14px;
      color: white;
      text-decoration: none;
      line-height: 32px;
      &:hover {
        color: $color-yellow-default;
      }
    }
  }
}

.headerTelephone {
  position: absolute;
  left: 300px;
  right: 20px;
  top: 70px;
  margin: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  a {
    margin-left: 16px;
    font-weight: bold;
    color: black;
    text-decoration: none;
  }
  @media (max-width: 1000px) {
    display: none;
  }
}

.headerTelephoneBottom {
  display: none;
  margin: 8px;
  text-align: center;
  a {
    display: inline-block;
    margin-top: 8px;
    font-weight: bold;
    color: black;
    text-decoration: none;
  }
  @media (max-width: 1000px) {
    display: block;
  }
}
